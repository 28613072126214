import React from 'react';
import './Components.css';
import DynamicIcon from '../Components/Helpers/DynamicIcon';

export default function LoadingPage() {

    return (
        <div className="loading-page">
            <DynamicIcon type="loadingScreen" width={'150'} height={'150'} />
        </div>
    );
}