import React, { useState, useEffect } from 'react';
import './Contact.css';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import DynamicIcon from '../../Components/Helpers/DynamicIcon';
import GoogleAnalytics from '../../Components/Helpers/GoogleAnalytics';
import { IconUser, IconMail, IconPencil } from '@tabler/icons-react';
import Validate from 'validate.js';
import { API_URL } from '../../Library/index';
import LoadingPage from '../../Components/LoadingPage';

export default function Contact() {
    GoogleAnalytics('/contact', {});
    const windowWidth = window.innerWidth;
    const [scrollY, setScrollY] = useState(0);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [errorMsg, setErrorMsg] = useState({
        name: null,
        email: null,
        message: null
    });

    const constraints = {
        email: {
            presence: {
                message: 'Required'
            },
            email: {
                message: "Please enter valid email"
            }
        },
        name: {
            presence: {
                message: 'Required'
            }
        },
        message: {
            presence: {
                message: 'Required'
            }
        }
    };

    useEffect(() => {
        function handlePageLoad() {
            if (document.readyState === "complete") {
                setIsPageLoaded(true);
                document.body.classList.remove('page-is-loading');
            }
        }

        document.body.classList.add('page-is-loading');

        handlePageLoad();
        document.addEventListener("readystatechange", handlePageLoad);

        return () => {
            document.removeEventListener("readystatechange", handlePageLoad);
            document.body.classList.remove('page-is-loading');
        };
    }, []);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function handleHowItWorks() {
        window.location = "/";
    }

    function handleAnalytics(type) {
        let objGA = {
            category: "Contact_Page",
            action: ""
        };

        if (type === 'IG') objGA.action = 'Contact_body_IG';
        else if (type === 'LN') objGA.action = 'Contact_body_LN';
        else if (type === 'X') objGA.action = 'Contact_body_X';

        GoogleAnalytics('', objGA);
    }

    async function handleSubmitSend() {
        setLoading(true);
        let check = Validate({
            email: email,
            name: name,
            message: message
        }, constraints);

        if (check) {
            setErrorMsg({
                ...errorMsg,
                name: (check?.name ? "Required" : null),
                email: (check?.email ? (check.email.length > 1 ? "Required" : "Enter valid email") : null),
                message: (check?.message ? "Required" : null)
            })
            setLoading(false);
            return;
        }

        var query = {
            query: `
                    mutation($input: UpdateInput!) {
                        sendUpdate(input: $input)
                    }
                `,
            variables: {
                input: {
                    subject: 'Arvel: Contact us question',
                    userEmail: email,
                    message: message
                }
            }
        }

        try {
            const resp = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(query)
            }).then((response) => response.json())
            if (resp.data.sendUpdate) {
                setSuccess(true);
                setLoading(false);

                let objGA = {
                    category: "Contact_page",
                    action: "Contact_message_submitted"
                };

                GoogleAnalytics('', objGA);
            }
        } catch (e) {
            console.log(e)
        }
    }

    function handleOnKeyPress(e) {
        if (e && e.charCode === 13) {
            handleSubmitSend();
        } else {
            setErrorMsg({
                name: null,
                email: null,
                message: null
            })
        }
    }

    const _renderSubmitButtonContent = () => {
        if (loading) return <DynamicIcon type="loadingWhiteCirle" width={'20'} height={'20'} />
        else return 'Send'
    }

    const _renderSuccesMsg = () => {
        let nameParts = name?.split(" ");
        let firstName = nameParts[0];
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);

        return "Thanks, " + firstName + "! Keep an eye on your inbox, we'll be reaching out soon 🤗"
    }

    const _renderMsgContainer = () => {
        if (success) return <>
            <DynamicIcon type="messageSentSuccess" width={'160'} height={'160'} />
            <div className='msg-sent-success'>
                <p>{_renderSuccesMsg()}</p>
                <div className='cu-btns-div'>
                    <div className='cub-header'>
                        <div className='cubdh-line' />
                        <p>stay in touch</p>
                    </div>
                    <div className='container'>
                        <a target="_blank" rel="noopener noreferrer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} href="https://www.instagram.com/arvel.app/" onClick={() => handleAnalytics('IG')}>
                            <img alt="icon-ig" loading='lazy' style={{ width: 29, height: 29 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/instagram.svg' />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/arvel" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 12px 0 11px' }} onClick={() => handleAnalytics('LN')}>
                            <img alt="icon-li" loading='lazy' style={{ width: 28, height: 28, margin: '0 3px' }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/linkedin.svg' />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/arvel_app" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleAnalytics('X')}>
                            <img alt="icon-x" loading='lazy' style={{ width: 26, height: 26 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/twitter.svg' />
                        </a>
                    </div>
                </div>
            </div>
        </>
        else return <>
            <div className='display-flex cu-msg-name-email-div'>
                <div className={(!errorMsg?.name ? 'user-input' : 'user-input error')}>
                    <IconUser stroke={2.4} width='22' color='#b9bec7' className='name-icon' />
                    <input name="email"
                        value={name || ''}
                        type="text"
                        placeholder="Name"
                        onChange={({ target: { value } }) => setName(value)}
                        onKeyDown={handleOnKeyPress} />
                    <span className="display-flex helper-txt">{errorMsg?.name}</span>
                </div>
                <div className={(!errorMsg?.email ? 'user-input' : 'user-input error')}>
                    <IconMail stroke={2} width='22' color='#b9bec7' className='email-icon' />
                    <input name="email"
                        value={email || ''}
                        type="email"
                        placeholder="Email"
                        onChange={({ target: { value } }) => setEmail(value)}
                        onKeyDown={handleOnKeyPress} />
                    <span className="display-flex helper-txt">{errorMsg?.email}</span>
                </div>
            </div>
            <div className={(!errorMsg?.message ? 'user-input' : 'user-input error-textarea')}>
                <IconPencil stroke={2} width='22' color='#b9bec7' className='message-icon' />
                <textarea
                    value={message || ''}
                    type="text"
                    placeholder="Message.."
                    onChange={({ target: { value } }) => setMessage(value)}
                    onKeyDown={handleOnKeyPress} />
                <span className="display-flex helper-txt">{errorMsg?.message}</span>
            </div>
            <button className='cu-submit-btn' onClick={handleSubmitSend} disabled={loading || success}>{_renderSubmitButtonContent()}</button>
        </>
    }

    const _renderLoadingPage = () => {
        if (!isPageLoaded) return <LoadingPage />
    }

    return (
        <div className={`main contact ${scrollY > 7 ? 'c-scrolled' : ''}`}>
            {_renderLoadingPage()}
            {windowWidth > 848 && <Header pageName={"Contact us - Arvel Help Center"} handleHowItWorks={handleHowItWorks} windowWidth={windowWidth} />}
            <div className='contact-body'>
                <div className='cb-clm-left'>
                    <Header pageName={"Contact us - Arvel Help Center"} handleHowItWorks={handleHowItWorks} windowWidth={windowWidth} />
                    <DynamicIcon type="contactUs" width={windowWidth > 849 ? '425' : '280'} height={windowWidth > 849 ? '425' : '280'} />
                </div>
                <div className='display-flex cb-clm-right'>
                    <div className='cb-clmr-inner'>
                        <h3 className='header-h3'>We're here to help!</h3>
                        <p><span style={{ fontWeight: '600' }}>Did you know? </span>Our website is filled with tips and tricks to help you experience world from different angles!</p>
                        <br />
                        <p>We try to answer emails as quickly as possible, but you may find it faster to reach us out on social media too.</p>
                        <div className='display-flex cb-clmr-social-media-div' style={{ display: 'flex' }}>
                            <a target="_blank" rel="noopener noreferrer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} href="https://www.instagram.com/arvel.app/" className="page-link" onClick={() => handleAnalytics('IG')}>
                                <img alt="icon-ig" loading='lazy' style={{ width: 27, height: 27 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/instagram.svg' />
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/arvel" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 8px 0 7px' }} className="page-link" onClick={() => handleAnalytics('LN')}>
                                <img alt="icon-li" loading='lazy' style={{ width: 27, height: 27, margin: '0 3px' }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/linkedin.svg' />
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/arvel_app" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="page-link" onClick={() => handleAnalytics('X')}>
                                <img alt="icon-x" loading='lazy' style={{ width: 23, height: 23 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/twitter.svg' />
                            </a>
                        </div>
                        <div className='cu-msg-container'>
                            {!success && <h3>Ask us anything!</h3>}
                            {_renderMsgContainer()}
                        </div>
                    </div>
                </div>
            </div>
            <Footer windowWidth={windowWidth} />
        </div>
    );
}