import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './Components.css';
import LogoTransperentRGB from '../images/logo_transparent_rgb.png';
import GoogleAnalytics from '../Components/Helpers/GoogleAnalytics';
import ImageLoader from '../Components/Helpers/ImageLoader';
import { Menu, Popover } from '@mantine/core';

const AUTH_TOKEN_KEY = "user_wnj_auth_token"; //wnj - Web NextJs

export default function Header(props) {
    const { pageName } = props;

    const [signedUser, setSignedUser] = useState(null);
    const [scrollY, setScrollY] = useState(0);

    const [showAccountPopover, setShowAccountPopover] = useState(false);
    const [showBLPopover, setShowBLPopover] = useState(false);
    const [showExplorePopover, setShowExplorePopover] = useState(false);
    const [showUserHomePopover, setShowUserHomePopover] = useState(false);

    const [myTripNodeCount, setMyTripNodeCount] = useState(0);

    useEffect(() => {
        // TODO: Integrate BE
        let userBucketList = localStorage.getItem('arvel-my-trip-obj-V2')
        const parsedData = JSON.parse(userBucketList);
        userBucketList = parsedData?.nodes || [];

        setMyTripNodeCount(userBucketList?.length || 0);
    }, []);

    useEffect(() => {
        let signedUser = JSON.parse(localStorage.getItem('aa-signed-user') || '{}') || {}
        setSignedUser(signedUser)
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    function handleAnalytics(type) {
        let objGA = {
            category: "Main_Page",
            action: ""
        };

        if (type === 'contact') objGA.action = 'Main_header_contact';
        else if (type === 'home') objGA.action = 'Main_header_home';
        else if (type === 'pricing') objGA.action = 'Main_header_pricing';
        else if (type === 'explore') objGA.action = 'Main_header_explore';
        else if (type === 'sign-in') objGA.action = 'Main_sign_in';
        else if (type === 'bl') objGA.action = 'Main_bucket_list';
        else if (type === 'user-home') objGA.action = 'Main_user_home';

        GoogleAnalytics('', objGA);
    }

    function _renderHelmet() {
        if (props.pageName === 'Main') return;
        else return <Helmet>
            <title>{props.pageName}</title>
        </Helmet>
    }

    const handleGoToUserProfile = () => {
        window.location.href = 'https://arvel.app/creators/' + signedUser?.username
    }

    const handleAddExperience = () => {
        window.location.href = 'https://arvel.app/creators/' + signedUser?.username + '/share-experience'
    }

    const handleLogOut = () => {
        localStorage.removeItem('aa-signed-user')
        localStorage.removeItem(AUTH_TOKEN_KEY);
        window.location.reload()
    }

    const handleGoToSignIn = () => {
        setShowAccountPopover(false)
        handleAnalytics('sign-in')

        window.location.href = '/users/sign-in'
    }

    const handleBucketList = () => {
        setShowBLPopover(false)
        handleAnalytics('bl');

        if (signedUser?.instagram) return window.location.href = '/bucket-list/' + signedUser?.instagram
        else return window.location.href = '/bucket-list/guest-user'
    };

    const handleExplore = () => {
        setShowExplorePopover(false)
        handleAnalytics('explore')

        window.location.href = '/experiences/worldwide'
    }

    const handleUserHome = () => {
        setShowUserHomePopover(false)
        handleAnalytics('user-home')

        window.location.href = '/users/home'
    }

    const _renderProfileBtn = () => {
        if (signedUser?.name) return <Menu width={200} withArrow={false}>
            <Menu.Target>
                <div className='signed-user-acount-btn-div'>
                    <ImageLoader src={signedUser?.img} skeletonH={46} skeletonW={46} skeletonR={'100%'} />
                </div>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item className='display-flex header-profile-dropdown-item profile' onClick={handleGoToUserProfile}>
                    <ImageLoader src={signedUser?.img} skeletonH={30} skeletonW={30} skeletonR={'100%'} />
                    <p>{signedUser?.name + ' ' + signedUser?.last_name?.charAt(0).toUpperCase() + '.'}</p>
                </Menu.Item>
                <Menu.Item className='display-flex header-profile-dropdown-item' onClick={handleAddExperience}>
                    <div className='icon-cover'>
                        <img alt="icon-bl" loading='lazy' style={{ width: 19, height: 19 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/add.svg' />
                    </div>
                    <p>Share experience</p>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item className='display-flex header-profile-dropdown-item' onClick={handleLogOut}>
                    <div className='icon-cover'>
                        <img alt="icon-bl" loading='lazy' style={{ width: 19, height: 19 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/log-out.svg' />
                    </div>
                    <p>Sign out</p>
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
        else return <Popover position="bottom" shadow="md" opened={showAccountPopover}>
            <Popover.Target>
                <button className='header-action-btn-div ab-margin-left' onClick={handleGoToSignIn} onMouseEnter={() => setShowAccountPopover(true)} onMouseLeave={() => setShowAccountPopover(false)}>
                    <img alt="icon-bl" loading='lazy' style={{ width: 22, height: 22 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/user.svg' />
                </button>
            </Popover.Target>
            <Popover.Dropdown style={{ pointerEvents: 'none', padding: '6px 15px', backgroundColor: 'rgb(50, 50, 50)', borderRadius: 10 }}>
                <p style={{ margin: 0, color: '#fff', fontSize: 14, letterSpacing: '0.02em' }}>Sign in</p>
            </Popover.Dropdown>
        </Popover>
    }

    return (
        <>
            {_renderHelmet()}
            <nav className={`header ${scrollY > 50 ? 'header-scrolled' : ''}`}>
                <div className="display-flex hdr-body">
                    <a href="/" className="header-logo" onClick={() => handleAnalytics('home')}>
                        <img alt="Logo" loading='lazy' src={LogoTransperentRGB} />
                    </a>
                    <div className="display-flex body-sub-2">
                        <Popover position="bottom" shadow="md" opened={showUserHomePopover}>
                            <Popover.Target>
                                <div className='display-flex header-action-btn-div' onClick={handleUserHome} onMouseEnter={() => setShowUserHomePopover(true)} onMouseLeave={() => setShowUserHomePopover(false)}>
                                    <img alt="icon-bl" loading='lazy' style={{ width: 23.5, height: 23.5 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/user-home.svg' />
                                </div>
                            </Popover.Target>
                            <Popover.Dropdown style={{ pointerEvents: 'none', padding: '6px 15px', backgroundColor: 'rgb(50, 50, 50)', borderRadius: 10 }}>
                                <p style={{ margin: 0, color: '#fff', fontSize: 14, letterSpacing: '0.02em' }}>Home</p>
                            </Popover.Dropdown>
                        </Popover>

                        <Popover position="bottom" shadow="md" opened={showBLPopover}>
                            <Popover.Target>
                                <div className='display-flex header-action-btn-div ab-margin-left' onClick={handleBucketList} onMouseEnter={() => setShowBLPopover(true)} onMouseLeave={() => setShowBLPopover(false)}>
                                    <img alt="icon-bl" loading='lazy' style={{ width: 22, height: 22 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/bookmark.svg' />
                                    {myTripNodeCount > 0 && <div className='mt-bl-count'>{myTripNodeCount}</div>}
                                </div>
                            </Popover.Target>
                            <Popover.Dropdown style={{ pointerEvents: 'none', padding: '6px 15px', backgroundColor: 'rgb(50, 50, 50)', borderRadius: 10 }}>
                                <p style={{ margin: 0, color: '#fff', fontSize: 14, letterSpacing: '0.02em' }}>Bucket list</p>
                            </Popover.Dropdown>
                        </Popover>

                        <Popover position="bottom" shadow="md" opened={showExplorePopover}>
                            <Popover.Target>
                                <div className='display-flex header-action-btn-div ab-margin-left' onClick={handleExplore} onMouseEnter={() => setShowExplorePopover(true)} onMouseLeave={() => setShowExplorePopover(false)}>
                                    <img alt="icon-bl" loading='lazy' style={{ width: 22, height: 22 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/search.svg' />
                                </div>
                            </Popover.Target>
                            <Popover.Dropdown style={{ pointerEvents: 'none', padding: '6px 15px', backgroundColor: 'rgb(50, 50, 50)', borderRadius: 10 }}>
                                <p style={{ margin: 0, color: '#fff', fontSize: 14, letterSpacing: '0.02em' }}>Explore</p>
                            </Popover.Dropdown>
                        </Popover>

                        {_renderProfileBtn()}
                    </div>
                </div>
            </nav>
        </>
    );
}