export default function DefineTrafficSource() {
    let init_source = JSON.parse(localStorage.getItem('aa-src-v1') || '{}') || {}
    let source, source_desc

    const queryString = window.location.search;

    const uriIndex = queryString.indexOf('?uri=');
    const endMarkerIndex = queryString.indexOf('i706') < 1 ? queryString.length : queryString.indexOf('i706');

    const param = queryString.substring(uriIndex + 5, endMarkerIndex);

    if (param === 'ig-pr101') {
        source = 'Instagram'
        source_desc = 'Profile link'
    } else if (param === 'uns-msg101') {
        source = 'Unsplash'
        source_desc = 'Travel photography'
    } else if (param === 'wf-pr101') {
        source = 'WellFound'
        source_desc = 'Company profile'
    } else if (param === 'ph-pr101') {
        source = 'ProductHunt'
        source_desc = 'Elnar profile'
    } else if (param === 'ph-ps101') {
        source = 'ProductHunt'
        source_desc = 'Product submit'
    } else if (param === 'bh-ar101') {
        source = 'BetaHunt'
        source_desc = 'Article, main page link'
    } else if (param === 'bh-ar102') {
        source = 'BetaHunt'
        source_desc = 'Article, waitlist link'
    } else if (param === 'ap-ep101') {
        source = 'Arvel web'
        source_desc = 'Waitlist, copy link'
    } else if (param === 'fb-pr101') {
        source = 'Facebook'
        source_desc = 'Profile link'
    } else if (param === 'x-pr101') {
        source = 'Twitter'
        source_desc = 'Arvel profile link'
    } else if (param === 'x-pr102') {
        source = 'Twitter'
        source_desc = 'Elnar profile link'
    } else if (param === 'x-ps101') {
        source = 'Twitter'
        source_desc = 'Elnar serendipity post'
    } else if (param === 'ln-pr101') {
        source = 'LinkedIn'
        source_desc = 'Profile link'
    } else if (param === 'ln-pr102') {
        source = 'LinkedIn'
        source_desc = 'Elnar profile link'
    } else if (param === 'rd-pr101') {
        source = 'Reddit'
        source_desc = 'Reddit post'
    } else if (param === 'rd-pr102') {
        source = 'Reddit'
        source_desc = 'Reddit post - may 2024'
    } else if (param === 'pb-pr101') {
        source = 'Pitch book'
        source_desc = 'Product submit - may 2024'
    } else if (param === 'tw-pr101') {
        source = '10words'
        source_desc = 'Product submit - may 2024'
    } else if (param === 'qr-pr101') {
        source = 'Quora'
        source_desc = 'Share post by Elnar - may 2024'
    } else if (param === 'yt-pr101') {
        source = 'Youtube'
        source_desc = 'Profile link'
    } else if (param === 'yt-vlv101') {
        source = 'Youtube'
        source_desc = 'Vlog: Canadian Rockies'
    } else if (param === 'igint1a1') {
        source = 'Instagram'
        source_desc = 'Creator invitation link - Apr 2024'
    } else if (param === 'igint1a2') {
        source = 'Instagram'
        source_desc = 'Reel likers invitation link - Sep 2024'
    } else if (param === 'igint1a2') {
        source = 'Instagram'
        source_desc = 'traveltwo_ reel commenters - Aug 2024'
    } else if (param === 'yt-vlv102') {
        source = 'Youtube'
        source_desc = 'Vlog: Ontario'
    } else if (init_source?.source && init_source?.source !== 'NA') {
        source = init_source?.source
        source_desc = init_source?.source_desc
    } else {
        source = 'NA'
        source_desc = 'NA'
    }

    localStorage.setItem('aa-src-v1', JSON.stringify({ source: source, source_desc: source_desc }));
}
