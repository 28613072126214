import React, { useState, useEffect, useRef } from 'react';
import { Skeleton } from '@mantine/core';

const ImageLoader = ({ src, imgClass, skeletonH, skeletonW, isFaded, skeletonR }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const imgRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); // Stop observing once visible
                }
            },
            { threshold: 0.1 }
        );

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => observer.disconnect();
    }, []);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const _renderImg = () => {
        return (
            <div
                ref={imgRef}
                style={{
                    position: 'relative',
                    height: skeletonH,
                    width: skeletonW,
                }}
                className={imgClass}
            >
                {!imageLoaded && (
                    <Skeleton
                        radius={skeletonR}
                        height={skeletonH}
                        width={skeletonW}
                        className={`${imgClass}`}
                        style={{ position: 'absolute', top: 0, left: 0 }}
                    />
                )}

                {isVisible && <img
                    src={src}
                    alt="img"
                    loading="lazy"
                    style={{
                        display: 'block',
                        opacity: imageLoaded ? 1 : 0,
                        transition: 'opacity 0.5s ease-in-out',
                    }}
                    onLoad={handleImageLoad}
                />
                }
            </div>
        );
    };

    return <>{_renderImg()}</>;
};

export default ImageLoader;
