import React from 'react';
import './Intro.css';
import Header from '../../../Components/Header';
import Fade from 'react-reveal/Fade';
import MAP_IMG from '../../../images/intro-map.webp';
import ImageLoader from '../../../Components/Helpers/ImageLoader';

export default function Intro(props) {
    const { windowWidth, pageName } = props;

    const handleLearnMore = () => window.location.href = '/our-story';

    return (
        <div id='intro-main' className="intro-main">
            <Header pageName={pageName} windowWidth={windowWidth} />
            <Fade>
                <div className="intro-container">
                    <div className='intro-div'>
                        <div className='id-desc'>
                            <h1 className='intro-header'>Imagine <span>Community</span>...</h1>
                            <p className='intro-body'>Where people share favorite experiences on one platform.</p>
                            <button className='display-flex into-learn-more-btn' onClick={handleLearnMore}>Learn more</button>
                        </div>
                        <div className='intro-img-container'>
                            <ImageLoader src={MAP_IMG} imgClass='intro-img' />
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
    );
}