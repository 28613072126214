import React, { useState, useEffect } from 'react';
import './Terms.css';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Fade from 'react-reveal/Fade';
import GoogleAnalytics from '../../Components/Helpers/GoogleAnalytics';
import LoadingPage from '../../Components/LoadingPage';

export default function Terms() {
    const windowWidth = window.innerWidth;
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    useEffect(() => {
        function handlePageLoad() {
            if (document.readyState === "complete") {
                setIsPageLoaded(true);
                document.body.classList.remove('page-is-loading');
            }
        }

        document.body.classList.add('page-is-loading');

        handlePageLoad();
        document.addEventListener("readystatechange", handlePageLoad);

        return () => {
            document.removeEventListener("readystatechange", handlePageLoad);
            document.body.classList.remove('page-is-loading');
        };
    }, []);

    GoogleAnalytics('/terms', {});

    const handleHowItWorks = () => window.location = "/";

    const _renderLoadingPage = () => {
        if (!isPageLoaded) return <LoadingPage />
    }

    return (
        <div id="main" className="main terms">
            {_renderLoadingPage()}
            <Header pageName='Terms of use - Arvel Help Center' handleHowItWorks={handleHowItWorks} windowWidth={windowWidth} />
            <Fade>
                <div className="terms-sec">
                    <h3 className='p-header'>Terms of Use</h3>
                    <p className='p-paragraph'>
                        By using the Arvel platform, these terms will
                        automatically apply to you – you should make sure therefore
                        that you read them carefully before using the platform. You’re not
                        allowed to copy, or modify the plaform, any part of the plaform, or
                        our trademarks in any way. You’re not allowed to attempt to
                        extract the source code of the plaform, and you also shouldn’t try
                        to translate the plaform into other languages, or make derivative
                        versions. The plaform itself, and all the trade marks, copyright,
                        database rights and other intellectual property rights related
                        to it, still belong to Arvel.
                    </p>
                    <p className='p-paragraph'>
                        Arvel is committed to ensuring that the plaform is
                        as useful and efficient as possible. For that reason, we
                        reserve the right to make changes to the plaform or to charge for
                        its services, at any time and for any reason. We will never
                        charge you for the plaform or its services without making it very
                        clear to you exactly what you’re paying for.
                    </p>
                    <p className='p-paragraph'>
                        The Arvel plaform stores and processes personal data that
                        you have provided to us, in order to provide our
                        Service. It’s your responsibility to keep your phone and
                        access to the plaform secure. We therefore recommend that you do
                        not jailbreak or root your phone, which is the process of
                        removing software restrictions and limitations imposed by the
                        official operating system of your device. It could make your
                        phone vulnerable to malware/viruses/malicious programs,
                        compromise your phone’s security features and it could mean
                        that the Arvel plaform won’t work properly or at all.
                    </p>
                    <p className='p-paragraph'>
                        You should be aware that there are certain things that
                        Arvel will not take responsibility for. Certain
                        functions of the plaform will require the plaform to have an active
                        internet connection. The connection can be Wi-Fi, or provided
                        by your mobile network provider, but Arvel
                        cannot take responsibility for the plaform not working at full
                        functionality if you don’t have access to Wi-Fi, and you don’t
                        have any of your data allowance left.
                    </p>
                    <p className='p-paragraph'>
                        If you’re using the plaform outside of an area with Wi-Fi, you
                        should remember that your terms of the agreement with your
                        mobile network provider will still apply. As a result, you may
                        be charged by your mobile provider for the cost of data for
                        the duration of the connection while accessing the plaform, or
                        other third party charges. In using the plaform, you’re accepting
                        responsibility for any such charges, including roaming data
                        charges if you use the plaform outside of your home territory
                        (i.e. region or country) without turning off data roaming. If
                        you are not the bill payer for the device on which you’re
                        using the plaform, please be aware that we assume that you have
                        received permission from the bill payer for using the plaform.
                    </p>
                    <p className='p-paragraph'>
                        Along the same lines, Arvel cannot always take
                        responsibility for the way you use the plaform i.e. You need to
                        make sure that your device stays charged – if it runs out of
                        battery and you can’t turn it on to avail the Service,
                        Arvel cannot accept responsibility.
                    </p>
                    <p className='p-paragraph'>
                        With respect to Arvel’s responsibility for your
                        use of the plaform, when you’re using the plaform, it’s important to
                        bear in mind that although we endeavour to ensure that it is
                        updated and correct at all times, we do rely on third parties
                        to provide information to us so that we can make it available
                        to you. Arvel accepts no liability for any
                        loss, direct or indirect, you experience as a result of
                        relying wholly on this functionality of the plaform.
                    </p>
                    <p className='p-paragraph'>
                        At some point, we may wish to update the plaform. The plaform is
                        currently available on Android &amp; iOS – the requirements for
                        both systems(and for any additional systems we
                        decide to extend the availability of the plaform to) may change,
                        and you’ll need to download the updates if you want to keep
                        using the plaform. Arvel does not promise that it
                        will always update the plaform so that it is relevant to you
                        and/or works with the Android &amp; iOS version that you have
                        installed on your device. However, you promise to always
                        accept updates to the application when offered to you, We may
                        also wish to stop providing the plaform, and may terminate use of
                        it at any time without giving notice of termination to you.
                        Unless we tell you otherwise, upon any termination, (a) the
                        rights and licenses granted to you in these terms will end;
                        (b) you must stop using the plaform, and (if needed) delete it
                        from your device.
                    </p>
                    <h3 className='p-header'>Changes to This Terms and Conditions</h3>
                    <p className='p-paragraph'>
                        We may update our Terms and Conditions
                        from time to time. Thus, you are advised to review this page
                        periodically for any changes. We will
                        notify you of any changes by posting the new Terms and
                        Conditions on this page.
                    </p>
                    <p className='p-paragraph'>
                        These terms and conditions are effective as of 2024-06-13.
                    </p>
                    <h3 className='p-header'>Contact Us</h3>
                    <p className='p-paragraph'>
                        If you have any questions or suggestions about our
                        Terms and Conditions, do not hesitate to contact us
                        at <a style={{ color: 'rgb(63, 163, 240)' }} href="mailto:info@arvel.app">info@arvel.app</a>.
                    </p>
                </div>
            </Fade>
            <Footer windowWidth={windowWidth} />
        </div>
    );
}