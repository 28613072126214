import React from "react";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import * as loadingWhiteCircle from "../../lottie/loading-circle-white.json";
import * as loadingCircleCompanyRGB from "../../lottie/loading-circle-companyRGB.json";
import * as notFound from "../../lottie/not-found.json";
import * as contactUs from "../../lottie/contact-us.json";
import * as resetPassword from '../../lottie/reset-password.json';
import * as introBanner from '../../lottie/intro-banner.json';
import * as surveyPet from '../../lottie/survey-pet.json';
import * as introMain from '../../lottie/intro-main.json';
import * as messageSentSuccess from '../../lottie/message-sent-success.json';
import * as referFriend from '../../lottie/refer-friend.json';
import * as refund from '../../lottie/refund.json';
import * as loadingScreen from '../../lottie/loading-screen.json';

export default function DynamicIcon(props) {
    var iconList = {
        loadingScreen: {
            loop: true,
            autoplay: true,
            animationData: loadingScreen.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        loadingWhiteCirle: {
            loop: true,
            autoplay: true,
            animationData: loadingWhiteCircle.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        loadingCircleCompanyRGB: {
            loop: true,
            autoplay: true,
            animationData: loadingCircleCompanyRGB.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        notFound: {
            loop: true,
            autoplay: true,
            animationData: notFound.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        contactUs: {
            loop: true,
            autoplay: true,
            animationData: contactUs.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        resetPassword: {
            loop: true,
            autoplay: true,
            animationData: resetPassword.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        introBanner: {
            loop: true,
            autoplay: true,
            animationData: introBanner.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        surveyPet: {
            loop: true,
            autoplay: true,
            animationData: surveyPet.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        introMain: {
            loop: true,
            autoplay: true,
            animationData: introMain.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        messageSentSuccess: {
            loop: true,
            autoplay: true,
            animationData: messageSentSuccess.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        referFriend: {
            loop: true,
            autoplay: true,
            animationData: referFriend.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        refund: {
            loop: true,
            autoplay: true,
            animationData: refund.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        }
    };

    return (
        !props.type
            ?
            ''
            :
            <FadeIn>
                <div className="d-flex justify-content-center align-items-center sr-lottie">
                    <Lottie options={iconList[props.type]} height={parseInt(props.height)} width={parseInt(props.width)} />
                </div>
            </FadeIn>
    );
}