import React, { useState, useEffect } from 'react';
import './TravelAlbum.css';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import DynamicIcon from '../../Components/Helpers/DynamicIcon';
import GoogleAnalytics from '../../Components/Helpers/GoogleAnalytics';
import CountUp from 'react-countup';
import DefineTrafficSource from './../../Components/Helpers/DefineTrafficSource';
import ImageLoader from '../../Components/Helpers/ImageLoader';
import SubscribeWaitlistModal from '../../Components/SubscribeWaitlistModal';
import LoadingPage from '../../Components/LoadingPage';

export default function Contact() {
    GoogleAnalytics('/waitlist', {});
    const windowWidth = window.innerWidth;
    const [scrollY, setScrollY] = useState(0);
    const [slideNum, setSlideNum] = useState(0);
    const [nodeViewsCount, setNodeViewsCount] = useState(0);
    const [isJoined, setIsJoined] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    const list = [
        {
            name: 'Petra & Jan',
            url: '/creators/wanderingintwo',
            location: 'Vancouver, Canada',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/wanderingintwo.jpg',
            descritpion: 'Share joyful moments and inspire others to have similar experiences'
        },
        {
            name: 'Ryan & Katy',
            url: '/creators/smilkos_lens',
            location: 'Santa Rosa, USA',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/smilkos_lens_2.webp',
            descritpion: 'Build travel history with interactive map and fun stats'
        },
        {
            name: 'Jana & Matej',
            url: '/creators/dreamyadventurers',
            location: 'Banff, Canada',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/dreamyadventurers_2.jpg',
            descritpion: "Find experiences you're into and dive into new adventures"
        },
        {
            name: 'Elnar',
            url: '/creators/eelnar',
            location: 'San Francisco, USA',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/pp-eelnar-187901.webp',
            descritpion: 'Share joyful moments and inspire others to have similar experiences'
        },
        {
            name: 'Kyle & Alannah',
            url: '/creators/wayoftheroads',
            location: 'Tofino, Canada',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/wayoftheroads_2.webp',
            descritpion: 'Build travel history with interactive map and fun stats'
        },
        {
            name: 'Sarah',
            url: '/creators/nanaimonoms',
            location: 'Nanaimo, Canada',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/nanaimonoms_2.jpg',
            descritpion: "Find experiences you're into and dive into new adventures"
        }
    ]

    useEffect(() => {
        function handlePageLoad() {
            if (document.readyState === "complete") {
                setIsPageLoaded(true);
                document.body.classList.remove('page-is-loading');
            }
        }

        document.body.classList.add('page-is-loading');

        handlePageLoad();
        document.addEventListener("readystatechange", handlePageLoad);

        return () => {
            document.removeEventListener("readystatechange", handlePageLoad);
            document.body.classList.remove('page-is-loading');
        };
    }, []);

    useEffect(() => {
        DefineTrafficSource();
    }, []);

    useEffect(() => {
        const slideInterval = setInterval(() => {
            renderSlide();
        }, 4000)

        return () => clearInterval(slideInterval);
    });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    const renderSlide = () => {
        if (slideNum === 5) setSlideNum(0);
        else setSlideNum(slideNum + 1);
    };

    const handleViewProfile = (URL) => {
        let objGA = {
            category: "Waitlist_web",
            action: 'Waitlist_web_view_profile'
        };
        GoogleAnalytics('', objGA);

        if (windowWidth > 601) window.open(URL);
        else window.location.href = URL;
    }

    const handleUpdate = (count, isJoined) => {
        setNodeViewsCount(count)
        setIsJoined(isJoined)
    }

    const _renderCoutUp = () => {
        if (isJoined) {
            return <>
                <span>{nodeViewsCount.toLocaleString()}</span>
                <span style={{ marginLeft: 3 }}>on waitlist</span>
            </>
        } else {
            return <>
                <CountUp
                    delay={0.5}
                    start={2500}
                    end={nodeViewsCount}
                    duration={3.5}
                />
                <span style={{ marginLeft: 3 }}>already did</span>
            </>
        }
    }

    const _renderLoadingPage = () => {
        if (!isPageLoaded) return <LoadingPage />
    }

    return (
        <div className={`main waitlist ${scrollY > 7 ? 'wl-scrolled' : ''}`}>
            {_renderLoadingPage()}
            {windowWidth > 848 && <Header pageName={"Waitlist - Arvel"} windowWidth={windowWidth} />}
            <div className='waitlist-body'>
                <div className='display-flex cb-clm-left'>
                    <Header pageName={"Waitlist - Arvel"} windowWidth={windowWidth} />
                    <DynamicIcon type="refund" width={windowWidth > 849 ? '425' : '280'} height={windowWidth > 849 ? '425' : '280'} />
                </div>
                <div className='display-flex cb-clm-right'>
                    <div className='cb-clmr-inner'>
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <h3 className='header-h3'>Arvel community <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span>launching soon</span></div></h3>
                        </div>

                        <div className='display-flex at-banner-img'>
                            <div className='display-flex at-img-div' onClick={() => handleViewProfile(list[slideNum].url)}>
                                {
                                    list.map(function (creator, index) {
                                        return <>
                                            {
                                                index === slideNum &&
                                                <ImageLoader src={creator.img} imgClass='display-flex at-img-container' />
                                            }
                                        </>
                                    })
                                }
                                <p className='at-name-hdr'>{list[slideNum].name}</p>
                                <p className='at-location-hdr'>{list[slideNum].location}</p>
                            </div>
                        </div>

                        <div className='display-flex at-body-txt'>
                            <p>Build, track, and share your travel history with fun stats and interactive map, while growing a community around your adventures.</p>
                            <div className='display-flex wtb-div'>
                                <SubscribeWaitlistModal handleUpdate={(count, isJoined) => handleUpdate(count, isJoined)} />
                                <div className='wtb-txt'>
                                    {_renderCoutUp()}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer windowWidth={windowWidth} />
        </div>
    );
}